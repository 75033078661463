import { lazy } from "react";
// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import {Protected,IsLogin} from 'utils/common.utils';
import   {getUserLocal}  from 'utils/localStorage.util';
const userDetails = getUserLocal(); //User Role Access Routes
// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard/Default")));

// utilities routing
// const Users = Loadable(lazy(() => import("views/utilities/users/users")));




// password routes
const ChangePassword = Loadable(
  lazy(() => import("views/utilities/change-password/change-password"))
);
// ==============================|| MAIN ROUTING ||============================== //
const Page404 = Loadable(
  lazy(() => import("views/utilities/404"))
);

//Advaisory Routes
const Advisory = Loadable(
  lazy(() => import("views/utilities/advisiory/index"))
);

//Advaisory-history Routes
const AdvisoryHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/index-history"))
);
//Advaisory-history Routes
const UsersServices = Loadable(
  lazy(() => import("views/utilities/advisiory/Users-Service/user-service"))
);

//Weather
const AdvisioryWheather = Loadable(
  lazy(() => import("views/utilities/advisiory/weather/addWhether.advisiory"))
);

const AdvisioryWheatherNotificationHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/weather/weatherHistory.advisory"))
);

//Pesticide
const AdvisioryPesticide = Loadable(
  lazy(() => import("views/utilities/advisiory/pesticide/addPesticide.advisiory"))
);

const AdvisioryPesticideHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/pesticide/pesticideHistory.advisory"))
);


//Information 
const AdvisioryInformation = Loadable(
  lazy(() => import("views/utilities/advisiory/importantInformation/addInformation.advisiory"))
);

const AdvisioryInformationHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/importantInformation/informationHistory.advisory"))
);


//Disease
const AdvisioryDisease = Loadable(
  lazy(() => import("views/utilities/advisiory/disease/addDisease.advisiory"))
);

const AdvisioryDiseaseHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/disease/diseaseHistory.advisory"))
);


//Fertiliser
const AdvisioryFertiliser = Loadable(
  lazy(() => import("views/utilities/advisiory/fertiliser/addFertiliser.advisiory"))
);

const AdvisioryFertiliserHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/fertiliser/fertiliserHistory.advisory"))
);


//Personalised
const AdvisioryPersonalised = Loadable(
  lazy(() => import("views/utilities/advisiory/personalisedCrop/addPersonalised.advisiory"))
);

const AdvisioryPersonalisedHistory = Loadable(
  lazy(() => import("views/utilities/advisiory/personalisedCrop/personalisedHistory.advisory"))
);

// Chat ui
const Chat = Loadable(
  lazy(() => import("../views/utilities/chat/index"))
);

// farmers contact
const ContactFarmers = Loadable(
  lazy(() => import("../views/utilities/contact/Card"))
);

// farmers Calendar
const Calendar = Loadable(
  lazy(() => import("../views/utilities/calendar/index"))
);


const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "*",
      element : <Page404 />
    },
    {
      path: "/dashboard",
      element :(
        <Protected isLoggedIn={userDetails}>
          <Dashboard />
        </Protected> 
      )
      // element: <Dashboard />,
    },
    {
      children: [
        {
          path: "/advisory",
          element: <Advisory />,
        },
      ],
    },
    {
      children: [
        {
          path: "/advisory-history",
          element: <AdvisoryHistory />,
        },
      ],
    },
    {
      children: [
        {
          path: "/user-service/:type/:id",
          element: <UsersServices />,
        },
      ],
    },
    {
      path : "/send-weather-notifications",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryWheather />
        </Protected> 
      )
    },
    {
      path : "/weather-notifications-advisory",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryWheatherNotificationHistory />
        </Protected> 
      )
    },
    {
      path : "/send-pesticide-notifications",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryPesticide />
        </Protected> 
      )
    },
    {
      path : "/notifications-pesticide",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryPesticideHistory />
        </Protected> 
      )
    },
    {
      path : "/send-information-notifications",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryInformation />
        </Protected> 
      )
    },
    {
      path : "/notifications-information",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryInformationHistory />
        </Protected> 
      )
    },
    {
      path : "/send-disease-notifications",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryDisease />
        </Protected> 
      )
    },
    {
      path : "/notifications-disease",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryDiseaseHistory />
        </Protected> 
      )
    },
    {
      path : "/send-fertiliser-notifications",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryFertiliser />
        </Protected> 
      )
    },
    {
      path : "/notifications-fertiliser",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryFertiliserHistory />
        </Protected> 
      )
    },
    {
      path : "/send-personalised-notifications",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryPersonalised />
        </Protected> 
      )
    },
    {
      path : "/notifications-personalised",
      element :(
        <Protected isLoggedIn={userDetails}>
          <AdvisioryPersonalisedHistory />
        </Protected> 
      )
    },
    // {
    //   children: [
    //     {
    //       path: "users",
    //       element: <Users />,
    //     },
    //   ],
    // },
    {
      children: [
        {
          path: "/chat",
          element: <Chat />,
        },
      ],
    },
    {
      children: [
        {
          path: "/contact-farmers",
          element: <ContactFarmers />,
        },
      ],
    },
    {
      children: [
        {
          path: "/calender-for-events",
          element: <Calendar />,
        },
      ],
    },
    {
      children: [
        {
          path: "/contact-farmers",
          element: <ContactFarmers />,
        },
      ],
    },
    {
      children: [
        {
          path: "change-passwrd",
          element: <ChangePassword />,
        },
      ],
    },
  ],
};

export default MainRoutes;
