import { Link } from 'react-router-dom';
// material-ui
import Logo from '../../../assets/images/KhetiMitra_Logo.svg';
// project imports

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({type}) => (
    <center>
          { (type == "Admin") ? (
            <Link to="dashboard"> 
                <img src={Logo} height={"55px"} alt="image" />
            </Link>
        ):(
            <Link> 
                <img src={Logo} height={"55px"} alt="image" />
            </Link>
        )}
    </center>
);

export default LogoSection;
