import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Service: [],
  ServiceType: [],
  ServicePackage: [],
  ActiveService: []

};
//internally using immer lib (can create mutable state)
export const serviceSlice = createSlice({
  name: "serviceData",
  initialState,
  reducers: {
    updateAllService: (state, action) => {
      state.Service = action.payload;
    },
    updateAllServiceType: (state, action) => {
      state.ServiceType = action.payload;
    },
    updateAllServicePackage: (state, action) => {
      state.ServicePackage = action.payload;
    },
    updateActiveService: (state, action) => {
      state.ActiveService = action.payload;
    },
  },
});
// this is for dispatch
export const { updateAllService,updateAllServiceType,updateAllServicePackage,updateActiveService } = serviceSlice.actions;
// this is for configureStore
export default serviceSlice.reducer;
