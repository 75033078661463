import Cookies from "js-cookie";

export const getTokenLocal = () => {
  return Cookies.get("x_auth_token_advisory");
};

export const getPermissionLocal = () => {
  const premission = Cookies.get("x_premission_advisory")
  if (premission) {
    return JSON.parse(premission);
  } else {
    return null;
  }
};

export const getUserLocal = () => {
  const user = Cookies.get("x_ufo_advisory");

  if (user !== null && user !== undefined) {
    return JSON.parse(user);
  } else {
    return null;
  }
};

export const setTokenLocal = (token) => {
  Cookies.set("x_auth_token_advisory", token, { expires: 30 });
};

export const setUserLocal = (user) => {
  Cookies.set("x_ufo_advisory", user, { expires: 30 });
};

export const setPermissionLocal = (user) => {
  Cookies.set("x_premission_advisory", JSON.stringify(user), { expires: 30 });
};

