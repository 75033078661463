import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  TotalSale: [],
  TotalPurchase: [],
  TotalFarmer: [],
  TotalProduct: [],
  TotalOrder: [],
  TotalWeather: [],//
  TotalComission: [],
  FranchiseSales:[],
  FranchiseUser:[],
  FranchiseOrder:[],
  TotalLead:[],
  InchargeTotal: [],
  StateMap : [],
  FranchiseCommission : [],



};
//internally using immer lib (can create mutable state)
export const dashBoardSlice = createSlice({
  name: "dashBoardData",
  initialState,
  reducers: {
    updateAllTotalSale: (state, action) => {
      state.TotalSale = action.payload;
    },
    updateAllTotalFarmer: (state, action) => {
      state.TotalFarmer = action.payload;
    },
    updateAllTotalProduct: (state, action) => {
      state.TotalProduct = action.payload;
    },
    updateAllTotalOrder: (state, action) => {
      state.TotalOrder = action.payload;
    },
    updateAllTotalWeather: (state, action) => {
      state.TotalWeather = action.payload;
    },
    updateAllTotalLead: (state, action) => {
      state.TotalLead = action.payload;
    },
    updateFranchiseOrder : (state, action) => {
      state.FranchiseOrder = action.payload;
    },
    updateFranchiseUser : (state, action) => {
      state.FranchiseUser = action.payload;
    },
    updateFranchiseSales : (state, action) => {
      state.FranchiseSales = action.payload;
    },
    updateVillageFranchise : (state, action) => {
      state.InchargeVillage = action.payload;
    },
    updateFranchiseCommission : (state, action) => {
      state.FranchiseCommission = action.payload;
    },
    updateVillageFranchise : (state, action) => {
      state.InchargeVillage = action.payload;
    },
    updateStateMap : (state, action) => {
      state.StateMap = action.payload;
    },
  },
});
// this is for dispatch
export const {
  updateAllTotalSale,
  updateAllTotalFarmer,
  updateAllTotalProduct,
  updateAllTotalOrder,
  updateAllTotalWeather,
  updateAllTotalComission,
  updateInchargeTotal,
  updateInchargeCommission,
  updateFranchiseOrder,
  updateFranchiseUser,
  updateFranchiseSales,
  updateAllTotalPurchase,
  updateVillageFranchise,
  updateStateMap,
  updateFranchiseCommission,
  updateAllTotalLead
} = dashBoardSlice.actions;
// this is for configureStore
export default dashBoardSlice.reducer;
