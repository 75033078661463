import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  Frencise: [],
  User: [],
};
//internally using immer lib (can create mutable state)
export const frenciseSlice = createSlice({
  name: "frenciseData",
  initialState,
  reducers: {
    updateAllFrencise: (state, action) => {
      state.Frencise = action.payload;
    },
    updateAllUser: (state, action) => {
      state.User = action.payload;
    },
  },
});
// this is for dispatch
export const { updateAllFrencise, updateAllUser } = frenciseSlice.actions;
// this is for configureStore
export default frenciseSlice.reducer;
