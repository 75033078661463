import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  UpdateSkelton: 'false',
};
//internally using immer lib (can create mutable state)
export const skeltonSlice = createSlice({
  name: "SkeltonState",
  initialState,
  reducers: {
    updateSkelton: (state, action) => {
      state.UpdateSkelton = action.payload;
    },
  },
});
// this is for dispatch
export const { updateSkelton } = skeltonSlice.actions;
// this is for configureStore
export default skeltonSlice.reducer;
