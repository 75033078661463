import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Ledger : [],
};

//internally using immer lib (can create mutable state)
export const userSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    updateLedger: (state, action) => {
      state.Ledger = action.payload;
    },
  },
});

// this is for dispatch
export const { updateLedger } = userSlice.actions;

// this is for configureStore
export default userSlice.reducer;
