// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// project imports
import snackbarReducer from './slices/snackbar';
import customerReducer from './slices/customer';
import contactReducer from './slices/contact';
import productReducer from './slices/product';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import mailReducer from './slices/mail';
import cartReducer from './slices/cart';
import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';

// import staffReducer from '../redux/redux-slice/user.slice'
import bannerReducer from "../redux/redux-slice/banner.slice";
import categoryReducer from "../redux/redux-slice/category.slice";
import subcategoryReducer from "../redux/redux-slice/sub-category.slice";
import serviceReducer from "../redux/redux-slice/service.slice";
import productsReducer from "../redux/redux-slice/product.slice";
import frenciseReducer from "../redux/redux-slice/frenchise.slice";
import userReducer from "../redux/redux-slice/user.slice";
import roleReducer from "../redux/redux-slice/role.slice";
import staffReducer from "../redux/redux-slice/staff.slice";
import attendenceReducer from "../redux/redux-slice/attendence.slice";
import holidayReducer from "../redux/redux-slice/holiday.slice";
import farmerReducer from "../redux/redux-slice/farmer.slice";
import checkoutReducer from "../redux/redux-slice/checkOut.slice";
import districtReducer from "../redux/redux-slice/district.slice";
import stateReducer from "../redux/redux-slice/state.slice";
import zipCodeReducer from "../redux/redux-slice/zipCode.slice";
import codReducer from "../redux/redux-slice/cod.slice";
import commissionReducer from "../redux/redux-slice/commission.slice";
import transactionSlice from "../redux/redux-slice/transaction.slice";
import dashBoardSlice from "../redux/redux-slice/dashBoard.slice";
import viewSlice from "../redux/redux-slice/view.slice";
import advisiorySlice from "../redux/redux-slice/advisory.slice";
import skeltonSlice from "../redux/redux-slice/skeltonState.slice";





// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  cart: persistReducer(
    {
      key: "cart",
      storage,
      keyPrefix: "berry-",
    },
    cartReducer
  ),
  kanban: kanbanReducer,
  customer: customerReducer,
  contact: contactReducer,
  product: productReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  mail: mailReducer,
  menu: menuReducer,
  banner: bannerReducer,
  category: categoryReducer,
  subCategory: subcategoryReducer,
  service: serviceReducer,
  products: productsReducer,
  frencise: frenciseReducer,
  user: userReducer,
  role: roleReducer,
  staff: staffReducer,
  attendence: attendenceReducer,
  holiday: holidayReducer,
  farmer: farmerReducer,
  checkOut: checkoutReducer,
  district: districtReducer,
  state: stateReducer,
  zip: zipCodeReducer,
  cod: codReducer,
  commission: commissionReducer,
  transaction: transactionSlice,
  dashboard  :dashBoardSlice,
  view : viewSlice,
  Advisory:advisiorySlice,
  Skelton: skeltonSlice,

});

export default reducer;
