
import { Navigate } from "react-router-dom";

export const formatDate = (date) => { return new Date(date).toLocaleString("en-in", { day: "numeric", month: "short", year: "numeric", }); }

export const inchargeNameChange = (name) => {
    switch (name) {
       case  'Franchise' : return 'Franchise'
       case  'Frenchise' : return 'Franchise'
       case  'Admin' : return 'Advisory'
       default : return 'Unknown'
    }
}

export const IsLogin = ({isLoggedIn,children}) =>{
    if(!isLoggedIn){
        return <Navigate to="/" replace />;
    }else{
        return children
    }
};

export const Protected = ({ isLoggedIn, children }) => {
    // console.log(isLoggedIn)
    if(!isLoggedIn){
        return <Navigate to="/" replace />;
    }else{
        if (isLoggedIn.type !== 'Admin') {
            return <Navigate to="/" replace />;
        }
        return children;
 }
};