// import dashboard from './dashboard';
import utilities from './utilities';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
        // items: [dashboard, utilities]
        items: [utilities]        

};

export default menuItems;
