// import { FormattedMessage } from "react-intl";
import { getUserLocal } from "utils/localStorage.util";

// assets
import {
  IconCategory,
  IconShoppingCart,
  IconBuildingStore,
  IconReport,
  IconMilitaryRank,
  IconBuildingSkyscraper,
  IconUsers,
  IconFileText,
  IconQuestionMark,
  IconFolder,
  IconBooks,
  IconListDetails,
  IconCash,
  IconBasket,
  IconLicense,
  IconSitemap,
  IconStackPush,
  IconZoomInArea,
  IconKeyframes,
  IconChartArea,
  IconAtom2,
  IconDashboard,
  IconServicemark,
  IconBrandSlack,
  IconHotelService,
  IconLayersDifference,
  IconBell,
  IconSend,
  IconCloud,
  IconPlus,
  IconHistory,
  IconAugmentedReality,//
  IconConfetti,//
  IconVirusSearch,//
  IconInfoCircle,//
  IconMedicineSyrup,//
  IconFileInvoice,
  IconMessages,
  IconCalendarEvent,
} from "@tabler/icons";

// constant
const icons = {
  IconCategory,
  IconShoppingCart,
  IconBuildingStore,
  IconReport,
  IconMilitaryRank,
  IconBuildingSkyscraper,
  IconUsers,
  IconFileText,
  IconQuestionMark,
  IconFolder,
  IconBooks,
  IconListDetails,
  IconCash,
  IconBasket,
  IconLicense,
  IconSitemap,
  IconStackPush,
  IconZoomInArea,
  IconKeyframes,
  IconChartArea,
  IconAtom2,
  IconFileInvoice,
  IconDashboard,
  IconServicemark,
  IconBrandSlack,
  IconHotelService,
  IconLayersDifference,
  IconSend,
  IconCloud,
  IconAugmentedReality,
  IconHistory,
  IconPlus,
  IconConfetti,
  IconInfoCircle,
  IconVirusSearch,
  IconMedicineSyrup,
  IconBell,
  IconMessages,
  IconCalendarEvent,
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const userId = getUserLocal();
var Parent = [];
if (userId) {
  // if (userId?.type === "Admin") {
    Parent = [
      {
        id: "default",
        title: "Dashboard",
        type: "item",
        url: "/dashboard",
        icon: icons.IconDashboard,
        breadcrumbs: false,
     },
      {
        id: "advisory",
        title: "Advisory",
        type: "item",
        url: "/advisory",
        icon: icons.IconSend,
        breadcrumbs: false,
     },
      {
        id: "advisory-history",
        title: "Advisory History",
        type: "item",
        url: "/advisory-history",
        icon: icons.IconHistory,
        breadcrumbs: false,
     },

      // {
      //   id: "advisory",
      //   title: "Advisory",
      //   type: "collapse",
      //   icon: icons.IconSend,
      //   breadcrumbs: false,
      //   children: [
          // {
          //   id: "weather",
          //   title: "Weather",
          //   type: "collapse",
          //   icon: icons.IconCloud,
          //   breadcrumbs: false,
          //   children: [
          //     {
          //       id: "Add-Weather",
          //       title: "Weather Forecast",
          //       type: "item",
          //       // icon: icons.IconPlus,
          //       url: "/send-weather-notifications",
          //       breadcrumbs: false,
          //     },
          //     {
          //       id: "notifaction-weather",
          //       title: "Forecast History",
          //       // icon: icons.IconHistory,
          //       type: "item",
          //       url: "/weather-notifications-advisory",
          //       breadcrumbs: false,
          //       },
          //   ],
          // },
          // {
          //   id: "Personalized-Crop",
          //   title: "Personalized Crop",
          //   type: "collapse",
          //   icon: icons.IconAugmentedReality,
          //   breadcrumbs: false,
          //   children: [
          //     {
          //       id: "Add-Personalized",
          //       title: "Personalized Advisory",
          //       type: "item",
          //       // icon: icons.IconPlus,
          //       url: "/send-personalised-notifications",
          //       breadcrumbs: false,
          //     },
          //     {
          //       id: "notifaction-personalised",
          //       title: "Advisory History",
          //       // icon: icons.IconHistory,
          //       type: "item",
          //       url: "/notifications-personalised",
          //       breadcrumbs: false,
          //       },
          //   ],
          // },
          // {
          //   id: "fertiliser",
          //   title: "Fertigation",
          //   type: "collapse",
          //   icon: icons.IconConfetti,
          //   breadcrumbs: false,
          //   children: [
          //     {
          //       id: "add-feartigation",
          //       title: "Fertigation Advisory",
          //       type: "item",
          //       // icon: icons.IconPlus,
          //       url: "/send-fertiliser-notifications",
          //       breadcrumbs: false,
          //     },
          //     {
          //       id: "notifaction-fertigation",
          //       title: "Fertigation History",
          //       // icon: icons.IconHistory,
          //       type: "item",
          //       url: "/notifications-fertiliser",
          //       breadcrumbs: false,
          //       },
          //   ],
          // },
          // {
          //   id: "weather",
          //   title: "Pesticide",
          //   type: "collapse",
          //   icon: icons.IconMedicineSyrup,
          //   breadcrumbs: false,
          //   children: [
          //     {
          //       id: "add-pesticide",
          //       title: "Pesticide Advisory",
          //       type: "item",
          //       // icon: icons.IconPlus,
          //       url: "/send-pesticide-notifications",
          //       breadcrumbs: false,
          //     },
          //     {
          //       id: "notifaction-pesticide",
          //       title: "Pesticide History",
          //       // icon: icons.IconHistory,
          //       type: "item",
          //       url: "/notifications-pesticide",
          //       breadcrumbs: false,
          //       },
          //   ],
          // },
          // {
          //   id: "disease",
          //   title: "Disease Alert",
          //   type: "collapse",
          //   icon: icons.IconVirusSearch,
          //   breadcrumbs: false,
          //   children: [
          //     {
          //       id: "Add-Disease",
          //       title: "Disease Advisory",
          //       type: "item",
          //       // icon: icons.IconPlus,
          //       url: "/send-disease-notifications",
          //       breadcrumbs: false,
          //     },
          //     {
          //       id: "notifaction-disease",
          //       title: "Disease History",
          //       // icon: icons.IconHistory,
          //       type: "item",
          //       url: "/notifications-disease",
          //       breadcrumbs: false,
          //       },
          //   ],
          // },
          // {
          //   id: "information",
          //   title: "Important Information",
          //   type: "collapse",
          //   icon: icons.IconInfoCircle,
          //   breadcrumbs: false,
          //   children: [
          //     {
          //       id: "Add-Information",
          //       title: "Send Information",
          //       type: "item",
          //       // icon: icons.IconPlus,
          //       url: "/send-information-notifications",
          //       breadcrumbs: false,
          //     },
          //     {
          //       id: "notifaction-information",
          //       title: "Information History",
          //       // icon: icons.IconHistory,
          //       type: "item",
          //       url: "/notifications-information",
          //       breadcrumbs: false,
          //       },
          //   ],
          // },
          {
            id: "farmers-information",
            title: "Farmer Information",
            type: "item",
            icon: icons.IconUsers,
            url: "/contact-farmers",
            breadcrumbs: false,
          },
          {
            id: "messages",
            title: "Messages",
            type: "item",
            icon: icons.IconMessages,
            url: "/chat",
            breadcrumbs: false,
          },
          // {
          //   id: "calenders",
          //   title: "Calender",
          //   type: "item",
          //   icon: icons.IconCalendarEvent,
          //   // url: "/calender-for-events",
          //   breadcrumbs: false,
          // },
    //     ],
    //   },
    ];
  // } 
}

const utilities = {
  id: "utilities",
  title: "Utilities",
  type: "group",
  children: Parent,
};

export default utilities;
